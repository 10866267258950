<template>
	<el-container>
		<el-aside width="230px">

			<SideMenu></SideMenu>

		</el-aside>
		<el-container>
			<el-header>
				<strong>FMES APP后台管理系统</strong>

				<div class="header-avatar" >

					<el-avatar size="medium" :src="userInfo.avatar"></el-avatar>

					<el-dropdown>
						<span class="el-dropdown-link" style="color: #FFFF;">
						{{userInfo.username}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>
								<router-link :to="{name: 'UserCenter'}" >个人中心</router-link>
							</el-dropdown-item>
							<el-dropdown-item @click.native="logout">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>

					<el-link href="https://vue.vgd100.com:18848/swagger-ui.html" target="_blank" style="color: #FFFF;">swaggerui</el-link>
					<el-link href="https://vue.bilibili.com/video/BV1af4y1s7Wh?spm_id_from=333.999.0.0&vd_source=d4914e99a97b22d3bcfc8cca36ff7c43 " target="_blank" style="color: #FFFF;">B站資源</el-link>
				</div>

			</el-header>
			<el-main>
				<Tabs></Tabs>
				<div style="margin: 0 15px;">
					<router-view/>
				</div>
			</el-main>
		 
			
		</el-container>
	</el-container>
</template>

<script>
	import SideMenu from "./inc/SideMenu";
	import Tabs from "./inc/Tabs";

	export default {
		name: "Home",
		components: {
			SideMenu, Tabs
		},
		data() {
			return {
				userInfo: {
					id: "",
					username: "",
					avatar: ""
				}
			}
		},
		created() {
			this.getUserInfo()
		},
		methods: {
			getUserInfo() {
				this.$axios.get("/sys/userInfo").then(res => {
					this.userInfo = res.data.data
				})
			},
			logout() {
				this.$axios.post("/logout").then(res => {
					localStorage.clear()
					sessionStorage.clear()

					this.$store.commit("resetState")

					this.$router.push("/login")
				})
			}
		}
	}
</script>

<style scoped>
	.el-container {
		padding: 0;
		margin: 0;
		height: 100%;
		/* background-color: #0691d7; */
	 color: #0691d7;
	}
.el-avatar{
	padding: 0;
		margin: 0;
		height: 22px;
		width: 22px;
		 
}
	.header-avatar {
		float: right;
		width: 260px;
	 
		display: flex;
		justify-content: space-around;
		align-items: center;
			color: #ffffff;
	}

	.el-dropdown-link {
		cursor: pointer;
	}

	.el-header {
		background-color: #545c64;  
		/* background: url(/assets/blackbar.png)  no-repeat; */
		background-size:100% 100%;
		color: #fcfcfd;
		text-align: center;
		line-height: 60px;

	}
	.el-footer{

		background-color: #545c64;  
		color: #fcfcfd;
		align-content: center;
		height: 12px;
	}

	.el-aside {
		background-color: #ffffff;
		color: rgb(109, 107, 107);
		line-height: 200px;
	}

	.el-main {
		
		color: rgb(109, 107, 107);
		padding: 0;
	}

	a {
		text-decoration: none;
	}
</style>